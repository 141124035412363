import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './Login/login.component';
import { RouterModule } from '@angular/router';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './Services/authentication.service';
import { FetchDataComponent } from './Exploitation-data/exploitation-data.component';
import { AuthorizationCheck } from './Services/authorizationCheck';
import {
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatGridListModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { FieldsetModule } from 'primeng/fieldset';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FileUploadModule } from 'primeng/fileupload';

import { httpInterceptor } from './Interceptor/httpInterceptor';
import { PackageService } from './Services/package.service';
import { DocumentsData } from './Documents-data/document.component';
import { UserManagement } from './user-management/userManagement.component';
import { UserService } from './Services/user.service';
import { AddUser } from './user-management/AddUsers/addUser.component';
import { EditUsers } from './user-management/EditUsers/editUsers.component';
import { Facturation } from './Facturation/facturation.component';
import { FacturationService } from './Services/facturation.service';
import { ReferentielService } from './Services/referentiel.service';
import { Referentiel } from './referentiel/referentiel.component';
import { RecipientData } from './referentiel/recipient/recipientData.component';
import { SpoolBack } from './RabbitMqBack/SpoolBack/spoolBack.component';
import { ReferentielBack } from './RabbitMqBack/ReferentielBack/referentielBack.component';
import { FlowTrackingService } from './Services/flowTracking.service';
import { SpoolDetail } from './RabbitMqBack/SpoolBack/SpoolDetail/SpoolDetail.component';
import { ReferentielDetail } from './RabbitMqBack/ReferentielBack/ReferentielDetail/ReferentielDetail.component';
import { senderManagement } from './RabbitMqBack/SenderManagement/senderManagement.component';
import { partnerManagement } from './RabbitMqBack/PartnerManagement/partnerManagement.component';
import { OkoroService } from './Services/okoro.service';
import { AddWsUser } from './user-management/AddWsUsers/addWsUser.component';
import { senderCoffreManagement } from './Okoro/SenderCoffre/senderCoffreManagement.component';
import { AddPartner } from './Configuration/AddPartner/addPartner.component';
import { documentTypeManagement } from './Configuration/DocumentType/documentTypeManagement.component';
import { FlowTrackingConfig } from './RabbitMqBack/Configuration/flowTrackingConfig.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    FetchDataComponent,
    DocumentsData,
    UserManagement,
    AddUser,
    AddWsUser,
    EditUsers,
    Facturation,
    Referentiel,
    RecipientData,
    SpoolBack,
    SpoolDetail,
    ReferentielBack,
    ReferentielDetail,
    FlowTrackingConfig,
    partnerManagement,
    senderManagement,
    senderCoffreManagement,
    AddPartner,
    documentTypeManagement
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatGridListModule,
    MatCardModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    MatDialogModule,
    MatRadioModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
    TableModule,
    DropdownModule,
    DynamicDialogModule,
    CheckboxModule,
    ButtonModule,
    RadioButtonModule,
    ToastModule,
    ConfirmDialogModule,
    AccordionModule,
    CardModule,
    CalendarModule,
    MultiSelectModule,
    DialogModule,
    TooltipModule,
    FieldsetModule,
    TriStateCheckboxModule,
    FileUploadModule,
    RouterModule.forRoot([
      { path: '', component: FetchDataComponent, pathMatch: 'full', canActivate: [AuthorizationCheck] },
      { path: 'login', component: LoginComponent },
      { path: 'exploitation-data', component: FetchDataComponent, canActivate: [AuthorizationCheck] },
      { path: 'users', component: UserManagement, canActivate: [AuthorizationCheck] },
      { path: 'addUser', component: AddUser, canActivate: [AuthorizationCheck] },
      { path: 'addWsUser', component: AddWsUser, canActivate: [AuthorizationCheck] },
      { path: 'facturation', component: Facturation, canActivate: [AuthorizationCheck] },
      { path: 'referentiel', component: Referentiel, canActivate: [AuthorizationCheck] },
      { path: 'spoolBack', component: SpoolBack, canActivate: [AuthorizationCheck] },
      { path: 'referentielBack', component: ReferentielBack, canActivate: [AuthorizationCheck] },
      { path: 'flowTrackingConfig', component: FlowTrackingConfig, canActivate: [AuthorizationCheck] },
      { path: 'partnerManagement', component: partnerManagement, canActivate: [AuthorizationCheck] },
      { path: 'senderManagement', component: senderManagement, canActivate: [AuthorizationCheck] },
      { path: 'senderCoffre', component: senderCoffreManagement, canActivate: [AuthorizationCheck] },
      { path: 'addPartner', component: AddPartner, canActivate: [AuthorizationCheck] },
      { path: 'documentType', component: documentTypeManagement, canActivate: [AuthorizationCheck] }
    ])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
    AuthorizationCheck,
    AuthenticationService,
    PackageService,
    UserService,
    FacturationService,
    ReferentielService,
    FlowTrackingService,
    OkoroService],
  bootstrap: [AppComponent],
  entryComponents: [DocumentsData, EditUsers, RecipientData, SpoolDetail, ReferentielDetail],
  exports: [
    //CdkTableModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ]
})
export class AppModule { }
