import { Component, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { FacturationService } from '../Services/facturation.service';
import { RoleEnum } from '../models/roleEnum';
import { ActifUser } from '../models/ActifUser';
import { SenderModel } from '../models/SenderModel';
import { MessageService } from "primeng/api";
import { UserService } from "../Services/user.service";

@Component({
  templateUrl: './facturation.component.html',
  styleUrls: ['./facturation.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class Facturation {

  loading: boolean;
  loadingButton: boolean;
  loadingButtonRecalcul: boolean;
  submitted = false;
  submittedRecalcul = false;
  endDateError = false;

  yearRange: string;

  facturationForm: FormGroup;
  recalculForm: FormGroup;
  public currentUser: ActifUser;
  sendersList: any[];
  originalSenders: any[];
  partners: any[];
  fr: any;
  selectedPartner: any;

  constructor(private facturationFormBuilder: FormBuilder, private userService: UserService, private facturationService: FacturationService, private messageService: MessageService) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    
    this.loading = true;
    this.loadingButton = false;
  }

  ngOnInit() {

    var date = new Date();
    date.setFullYear(new Date().getFullYear() + 10);
    this.yearRange = (new Date().getFullYear() - 1) + ":" + date.getFullYear();

    this.fr = {
      firstDayOfWeek: 1,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec"],
      today: "Aujourd'hui",
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };

    let requestId: string;

    if (this.currentUser.roleId == RoleEnum.PART) {
      requestId = this.currentUser.partnerId;
    }
    else if (this.currentUser.roleId == RoleEnum.SENDER) {
      requestId = this.currentUser.clientId;
    }

    this.userService.getAllPartners().subscribe(partners => {
      this.partners = partners;
      this.selectedPartner = this.partners.find(x => x.id == requestId);
    });

    this.facturationService.getSenders(requestId).subscribe(senders => {
      this.originalSenders = senders

      if (this.currentUser.roleId == 1 || this.currentUser.roleId == 2 || this.currentUser.roleId == 6) {
        this.facturationForm.setControl('partner', new FormControl("", { validators: Validators.required }));
        this.facturationControl.sender.disable();
      } else if (this.currentUser.roleId == 7) {
        this.facturationControl.sender.disable();
      }
      else {
        this.sendersList = this.originalSenders.map(x => (
          {
            value: x.pid,
            label: x.label + ' (' + x.pid + ')'
          }));
      }
      this.loading = false;
    });

    this.facturationForm = this.facturationFormBuilder.group({
      sender: new FormControl("", { validators: Validators.required }),
      from: new FormControl("", { validators: Validators.required }),
      to: new FormControl("", { validators: Validators.required })
    }, {
        validator: this.MustBeLessThan1Year('from', 'to')
      });



    this.recalculForm = this.facturationFormBuilder.group({
      month: new FormControl("", { validators: Validators.required })
    });
  }

  onPartnerChanged(e) {
    this.facturationControl.sender.setValue("");

    this.selectedPartner = e;

    if (this.selectedPartner != undefined) {
      if (this.selectedPartner.id == 0) {
        this.facturationControl.sender.setValue("");
        this.facturationControl.sender.disable();
      }
      else {
        this.sendersList = this.originalSenders.filter(sender => sender.partnerId == this.selectedPartner.id);
        this.sendersList = this.sendersList.map(x => (
          {
            value: x.pid,
            label: x.label + ' (' + x.pid + ')'
          }));
        this.facturationControl.sender.enable();
      }
    }
  }

  // convenience getter for easy access to form fields
  get facturationControl() { return this.facturationForm.controls; }
  get recalculControl() { return this.recalculForm.controls; }

  MustBeLessThan1Year(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      let splitedFrom = control.value.split("/");
      let splitedTo = matchingControl.value.split("/");

      let monthFrom = splitedFrom[0];
      let yearFrom = splitedFrom[1];

      let monthTo = splitedTo[0];
      let yearTo = splitedTo[1];

      let dateFrom = new Date(yearFrom, monthFrom);
      let dateTo = new Date(yearTo, monthTo);

      let estimatedDate = new Date(yearFrom, monthFrom);
      estimatedDate.setDate(estimatedDate.getDate() + 365);

      // set error on matchingControl if validation fails
      if (dateTo > estimatedDate) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  compareTwoDates(dateFrom: Date, dateTo: Date) {
    if (dateTo < dateFrom) {
      return false;
    }
    else {
      return true;
    }
  }

  generate() {
    
    this.loadingButton = true;
    this.submitted = true;

    let monthFrom: any;
    let yearFrom: any;
    let monthTo: any;
    let yearTo: any;

    if (this.facturationForm.value.from != null && this.facturationForm.value.to != null) {
      let splitedFrom = this.facturationForm.value.from.split("/");
      let splitedTo = this.facturationForm.value.to.split("/");

      monthFrom = splitedFrom[0];
      yearFrom = splitedFrom[1];

      monthTo = splitedTo[0];
      yearTo = splitedTo[1];

      let dateFrom = new Date(yearFrom, monthFrom);
      let dateTo = new Date(yearTo, monthTo);

      let isCorrect = this.compareTwoDates(dateFrom, dateTo);

      // stop here if endDate is upper than startDate
      if (!isCorrect) {
        this.endDateError = true;
        this.loadingButton = false;
        return null;
      }
      else {
        this.endDateError = false;
      }
    }

    if (this.currentUser.roleId == RoleEnum.SENDER) {
      this.facturationForm.value.sender = [this.currentUser.clientLabel];
    }

    // stop here if form is invalid
    if (this.facturationForm.invalid) {
      this.loadingButton = false;
      return;
    }

    var partnerCode = this.selectedPartner.code;

    this.facturationService.getFacturation(this.facturationForm.value.sender, this.facturationForm.value.from, this.facturationForm.value.to).subscribe((base64Pdf: string) => {
      this.loadingButton = false;
      this.submitted = false;
      if ((this.currentUser.roleId == 1 || this.currentUser.roleId == 2 || this.currentUser.roleId == 6)) {
        this.facturationControl.sender.disable();
      }
      
      this.facturationForm.reset();

      const arrayBuffer = base64ToArrayBuffer(base64Pdf);
      createAndDownloadBlobFile(arrayBuffer, partnerCode + '_facturation_' + monthFrom + yearFrom + "_" + monthTo + yearTo);
    },
      error => {
        this.loadingButton = false;
        this.submitted = false;
        this.messageService.add({ severity: 'warn', summary: 'Attention', detail: "Pas de facturation pour la période choisie.", life: 4000 });
        console.error(error);
      }
    );
  }

  recalculFacturation() {
    this.loadingButtonRecalcul = true;
    this.submittedRecalcul = true;

    // stop here if form is invalid
    if (this.recalculForm.invalid) {
      this.loadingButton = false;
      return;
    }

    this.facturationService.calculateFacturation(this.recalculForm.value.month).subscribe((result: boolean) => {
      this.loadingButtonRecalcul = false;
      this.submittedRecalcul = false;
      this.recalculForm.reset();
      if (result) {
        this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le calcul s'est effectué avec succès", life: 4000 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Un problème est survenu lors du calcul de la facturation.", life: 4000 });
      }

    },
      error => console.error(error)
    );
  }
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndDownloadBlobFile(body, filename, extension = 'csv') {
  const blob = new Blob([body]);
  const fileName = `${filename}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
