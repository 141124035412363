import { Injectable, EventEmitter, Output, HostListener, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Router } from "@angular/router";
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class AuthenticationService {
  @Output() fireIsLoggedIn: EventEmitter<any> = new EventEmitter<any>();

  private loggedIn = new BehaviorSubject<boolean>(false);
  url: string;
  public UserApiServerLink: string;

  get isLoggedIn() {
    if (sessionStorage.getItem('currentUser')) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
    return this.loggedIn.asObservable();
  }

  getEmitter() {
    return this.fireIsLoggedIn;
  }

  constructor(
    private http: HttpClient,
    private router: Router, @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl;
    // Recupération de l'adresse du serveur d'authentification
    this.http.get(this.url + 'api/params/GetUserApiServerLink', { responseType: 'text' }).subscribe(result => {
      this.UserApiServerLink = result;
    });
  }
  
  login(username: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'rejectUnauthorized': 'false'
      })
    };
    
    return this.http.post<any>(this.url + 'api/user/LoginUser', { username, password })
      .pipe(map(user => {
        // Login successful if there's a role in the response
        if (user.currentUser && user.currentUser.roleId) {
          // Store user details and role in local storage to keep user Logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(user.currentUser));
          this.fireIsLoggedIn.emit(user.currentUser); // you can pass here whatever you want
          this.loggedIn.next(true);
        }

        return user;
      }))
  }

  logout() {
    // remove user from Local storage to log user out
    sessionStorage.removeItem('currentUser');
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

}
