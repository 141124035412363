import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationCheck implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authenticationService.isLoggedIn
      .pipe(
        take(1),
        map((isLoggedIn: boolean) => {
          if ((!isLoggedIn) || (!sessionStorage.getItem('currentUser'))) {
            this.router.navigate(['/login']);
            return false;
          }
          return true;
        }));
  }
}
