import { Component, Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { PackageService } from '../Services/package.service';
import { ActifUser } from '../models/ActifUser';
import { PackageModel } from '../models/PackageModel';
import { RoleEnum } from '../models/roleEnum';
import { DialogService, MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { DocumentsData } from '../Documents-data/document.component';
import { DocumentModel } from '../models/DocumentModel';
import { StatusModel } from '../models/StatusModel';
import { Subscription, interval } from 'rxjs';
import { DigiposteStatusEnum } from '../models/DigiposteStatusEnum';
import { MailevaStatusEnum } from '../models/MailevaStatusEnum';
import { DatePipe } from '@angular/common';
import { OkoroStatusEnum } from '../models/OkoroStatusEnum';
import { GlobalStatusEnum } from '../models/GlobalStatusEnum';

@Component({
  selector: 'app-exploitation-data',
  templateUrl: './exploitation-data.component.html',
  styleUrls: ['./exploitation-data.component.css'],
  providers: [DialogService, MessageService, DatePipe]
})

export class FetchDataComponent {

  loading = true;
  firstLoading = true;
  loadingDoc = false;
  hideCompteur = true;
  freezeButton: boolean;
  dialogDisplay: boolean;
  loadingButton = false;

  originalCollection: PackageModel[];
  copyOriginalCollection: PackageModel[];
  selectedPackage: PackageModel;
  allStatus: StatusModel[];
  updateSubscription: Subscription;
  currentUser: ActifUser;
  event: LazyLoadEvent;
  filter: any;

  globalStatus: any[];
  digiposteStatus: any[];
  mailevaStatus: any[];
  okoroStatus: any[];
  globalDocumentStatus: any[];
  digiposteDocumentStatus: any[];
  mailevaDocumentStatus: any[];
  okoroDocumentStatus: any[];
  columns: any[];
  fr: any;

  url: string
  compteur: number;
  totalRecords: number;
  intervalId: any;
  refreshSecond: number;
  requestId: string;
  selectedGlobalStatus: string;
  selectedDigiposteStatus: string;
  selectedMailevaStatus: string;
  selectedOkoroStatus: string;
  isFilteredTable: boolean;
  yearRange: string;
  note: string;

  constructor(private packageService: PackageService, @Inject('BASE_URL') baseUrl: string, public dialogService: DialogService, private messageService: MessageService, public datepipe: DatePipe) {
    this.url = baseUrl;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.hideCompteur = true;
    this.freezeButton = false;
    this.isFilteredTable = false;
    this.dialogDisplay = false;

    if (this.currentUser.roleId == RoleEnum.PART) {
      this.requestId = this.currentUser.partnerId;
    }
    else if (this.currentUser.roleId == RoleEnum.SENDER) {
      this.requestId = this.currentUser.clientId;
    }
    
    //setTimeout(() => {
    //  this.packageService.getPackagesLazy(this.filter).subscribe(packages => {
    //    this.totalRecords = packages.count;
    //    this.copyOriginalCollection = packages.packageList;
    //  });
    //}, 1000);

    this.packageService.getPackageStatus().subscribe(packagesStatus => {
      this.allStatus = packagesStatus;

      this.globalStatus = this.allStatus.filter(x => x.idRepository == 1);
      this.globalStatus = this.globalStatus.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.globalStatus.unshift({
        value: null,
        label: null
      });

      this.digiposteStatus = this.allStatus.filter(x => x.idRepository == 3);
      this.digiposteStatus = this.digiposteStatus.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.digiposteStatus.unshift({
        value: null,
        label: null
      });
      this.mailevaStatus = this.allStatus.filter(x => x.idRepository == 4);
      this.mailevaStatus = this.mailevaStatus.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.mailevaStatus.unshift({
        value: null,
        label: null
      });

      this.okoroStatus = this.allStatus.filter(x => x.idRepository == 2);
      this.okoroStatus = this.okoroStatus.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.okoroStatus.unshift({
        value: null,
        label: null
      });
    });

    this.packageService.getDocumentStatus().subscribe(documentStatus => {

      this.globalDocumentStatus = documentStatus.filter(x => x.idRepository == 1);
      this.globalDocumentStatus = this.globalDocumentStatus.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.globalDocumentStatus.unshift({
        value: null,
        label: null
      });
      this.digiposteDocumentStatus = documentStatus.filter(x => x.idRepository == 3);
      this.digiposteDocumentStatus = this.digiposteDocumentStatus.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.digiposteDocumentStatus.unshift({
        value: null,
        label: null
      });
      this.mailevaDocumentStatus = documentStatus.filter(x => x.idRepository == 4);
      this.mailevaDocumentStatus = this.mailevaDocumentStatus.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.mailevaDocumentStatus.unshift({
        value: null,
        label: null
      });
      this.okoroDocumentStatus = documentStatus.filter(x => x.idRepository == 2);
      this.okoroDocumentStatus = this.okoroDocumentStatus.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.okoroDocumentStatus.unshift({
        value: null,
        label: null
      });
    });

    this.columns = [
      { field: 'statusGlobal', header: 'Statut global' },
      { field: 'lotId', header: 'Id lot' },
      { field: 'depositDate', header: 'Date de dépôt' },
      { field: 'clientName', header: 'Client' },
      { field: 'clientCode', header: 'Code client' },
      { field: 'statusDigiposte', header: 'Statut' },
      { field: 'statusMaileva', header: 'Statut' }
    ];

    if (this.currentUser.isOkoro != false || !this.requestId) {
      this.columns.push({ field: 'statusOkoro', header: 'Statut' });
    }

  }

  loadPackageLazy(event: LazyLoadEvent) {
    this.loading = true;
    this.event = event;

    this.filter = {
      partnerOrClientId: this.requestId,
      pid: this.currentUser.clientLabel,
      roleId: this.currentUser.roleId,
      first: event.first,
      rows: event.rows,
      statusGlobal: event.filters != null && event.filters.statusGlobal != null ? event.filters.statusGlobal.value : null,
      lotId: event.filters != null && event.filters.lotId != null ? event.filters.lotId.value : null,
      depositDate: event.filters != null && event.filters.depositDate != null ? event.filters.depositDate.value : null,
      clientName: event.filters != null && event.filters.clientName != null ? event.filters.clientName.value : null,
      clientCode: event.filters != null && event.filters.clientCode != null ? event.filters.clientCode.value : null,
      statusDigiposte: event.filters != null && event.filters.statusDigiposte != null ? event.filters.statusDigiposte.value : null,
      statusMaileva: event.filters != null && event.filters.statusMaileva != null ? event.filters.statusMaileva.value : null,
      statusOkoro: event.filters != null && event.filters.statusOkoro != null ? event.filters.statusOkoro.value : null,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };

    setTimeout(() => {
      this.packageService.getPackagesLazy(this.filter).subscribe(packages => {
        this.totalRecords = packages.count;
        this.originalCollection = packages.packageList;
        this.copyOriginalCollection = packages.packageList;

        this.loading = false;
        this.firstLoading = false;
        this.hideCompteur = false;
      });
    }, 1000);
  }

  ngOnInit() {
    var date = new Date();
    date.setFullYear(new Date().getFullYear() + 10);

    this.yearRange = (new Date().getFullYear() - 1) + ":" + date.getFullYear();

    this.fr = {
      firstDayOfWeek: 1,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec"],
      today: "Aujourd'hui",
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };

    // Recupération du parametre de refresh
    this.packageService.getRefreshInSecond().subscribe(result => {
      this.refreshSecond = result;
      this.compteur = this.refreshSecond;
      // Affichage du compte à rebours
      this.intervalId = setInterval(() => {
        if (this.compteur > 0) {
          this.compteur = this.compteur - 1;
        }
        else {
          this.hideCompteur = true;
        }
      }, 1000);

      this.subscribeRefresh();

    }, error => console.error(error));
  }

  ngOnDestroy() {
    // clear interval 
    this.updateSubscription.unsubscribe();
  }

  onFilterTable(event) {
    this.isFilteredTable = Object.keys(event.filters).length != 0;
  }

  subscribeRefresh() {
    this.updateSubscription = interval(this.refreshSecond * 1000).subscribe(
      (val) => {

        setTimeout(() => {
          this.packageService.getPackagesLazy(this.filter).subscribe(packages => {
            this.totalRecords = packages.count;
            this.originalCollection = packages.packageList;

            this.loading = false;
            this.hideCompteur = false;

            // réinitialiser le compteur
            this.compteur = this.refreshSecond;
          });
        }, 1000);

        this.packageService.getPackageStatus().subscribe(packagesStatus => {
          this.allStatus = packagesStatus;

          this.globalStatus = this.allStatus.filter(x => x.idRepository == 1);
          this.globalStatus = this.globalStatus.map(x => (
            {
              value: x.label,
              label: x.label
            }));
          this.globalStatus.unshift({
            value: null,
            label: null
          });
          this.digiposteStatus = this.allStatus.filter(x => x.idRepository == 3);
          this.digiposteStatus = this.digiposteStatus.map(x => (
            {
              value: x.label,
              label: x.label
            }));
          this.digiposteStatus.unshift({
            value: null,
            label: null
          });
          this.mailevaStatus = this.allStatus.filter(x => x.idRepository == 4);
          this.mailevaStatus = this.mailevaStatus.map(x => (
            {
              value: x.label,
              label: x.label
            }));
          this.mailevaStatus.unshift({
            value: null,
            label: null
          });
          this.okoroStatus = this.allStatus.filter(x => x.idRepository == 2);
          this.okoroStatus = this.okoroStatus.map(x => (
            {
              value: x.label,
              label: x.label
            }));
          this.okoroStatus.unshift({
            value: null,
            label: null
          });
        });

        this.packageService.getDocumentStatus().subscribe(documentStatus => {

          this.globalDocumentStatus = documentStatus.filter(x => x.idRepository == 1);
          this.globalDocumentStatus = this.globalDocumentStatus.map(x => (
            {
              value: x.label,
              label: x.label
            }));
          this.globalDocumentStatus.unshift({
            value: null,
            label: null
          });
          this.digiposteDocumentStatus = documentStatus.filter(x => x.idRepository == 3);
          this.digiposteDocumentStatus = this.digiposteDocumentStatus.map(x => (
            {
              value: x.label,
              label: x.label
            }));
          this.digiposteDocumentStatus.unshift({
            value: null,
            label: null
          });
          this.mailevaDocumentStatus = documentStatus.filter(x => x.idRepository == 4);
          this.mailevaDocumentStatus = this.mailevaDocumentStatus.map(x => (
            {
              value: x.label,
              label: x.label
            }));
          this.mailevaDocumentStatus.unshift({
            value: null,
            label: null
          });
          this.okoroDocumentStatus = documentStatus.filter(x => x.idRepository == 2);
          this.okoroDocumentStatus = this.okoroDocumentStatus.map(x => (
            {
              value: x.label,
              label: x.label
            }));
          this.okoroDocumentStatus.unshift({
            value: null,
            label: null
          });
        });
      });
  }

  showDocumentByPackage(selectedPackage) {
    let packageId = selectedPackage.id;    

    this.loadingDoc = true;

    const ref = this.dialogService.open(DocumentsData, {
      data: {
        // Passing data to documentData component
        id: packageId,
        totalDocCount: selectedPackage.totalDocCount,
        globalStatus: this.globalDocumentStatus,
        digiposteStatus: this.digiposteDocumentStatus,
        mailevaStatus: this.mailevaDocumentStatus,
        okoroStatus: this.okoroDocumentStatus,
        loading: this.loadingDoc
      },
      header: 'Id lot : ' + selectedPackage.lotId,
      width: '90%',
      contentStyle: { "max-height": "40em", "overflow": "auto" }
    });
  }

  getXmlReceived(selectedPackage) {
    this.loading = true;
    let packageId = selectedPackage.id;

    this.packageService.getXmlReceived(packageId).subscribe((base64Pdf: string) => {
      this.loading = false;

      const arrayBuffer = base64ToArrayBuffer(base64Pdf);
      createAndDownloadBlobFile(arrayBuffer, selectedPackage.lotId + "_received", '.xml');
    },
      error => {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Attention', detail: "Erreur lors du téléchargement de l'xml.", life: 4000 });
        console.error(error);
      }
    );
  }

  getXmlSent(selectedPackage) {
    this.loading = true;
    let packageId = selectedPackage.id;

    this.packageService.getXmlSent(packageId).subscribe((base64Pdf: string) => {
      this.loading = false;

      const arrayBuffer = base64ToArrayBuffer(base64Pdf);
      createAndDownloadBlobFile(arrayBuffer, selectedPackage.lotId + "_sent", '.xml');
    },
      error => {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Attention', detail: "Erreur lors du téléchargement de l'xml.", life: 4000 });
        console.error(error);
      }
    );
  }

  getOkoroInd(selectedPackage) {
    this.loading = true;
    let packageId = selectedPackage.id;

    this.packageService.getOkoroInd(packageId).subscribe((base64Pdf: string) => {
      this.loading = false;

      const arrayBuffer = base64ToArrayBuffer(base64Pdf);
      createAndDownloadBlobFile(arrayBuffer, selectedPackage.lotId, '.ind');
    },
      error => {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Attention', detail: "Erreur lors du téléchargement de l'ind.", life: 4000 });
        console.error(error);
      }
    );
  }

  onRowEditInit(selectedPackage: PackageModel) {

    clearInterval(this.intervalId);
    this.updateSubscription.unsubscribe();
    this.compteur = 0;
    this.hideCompteur = true;
    //////////////////////////////////
    this.selectedGlobalStatus = selectedPackage.statusGlobal;
    this.selectedDigiposteStatus = selectedPackage.statusDigiposte;
    this.selectedMailevaStatus = selectedPackage.statusMaileva;
    this.selectedOkoroStatus = selectedPackage.statusOkoro;
  }

  onRowEditSave(selectedPackage: PackageModel) {

    this.selectedPackage = selectedPackage;

    var originalPackage = this.originalCollection.filter(x => x.id == this.selectedPackage.id)[0];

    if (this.selectedGlobalStatus != originalPackage.statusGlobal
      || this.selectedDigiposteStatus != originalPackage.statusDigiposte
      || this.selectedMailevaStatus != originalPackage.statusMaileva
      || this.selectedOkoroStatus != originalPackage.statusOkoro) {
      this.freezeButton = true;
      this.dialogDisplay = true;
    }
    else {
      this.reinitializeCompteur();
    }
  }

  cancelRowEdit() {
    this.dialogDisplay = false;
    this.freezeButton = false;

    this.reinitializeCompteur();
  }

  confirmRowEdit() {
    this.loadingButton = true;

    var packageToUpdate = JSON.parse(JSON.stringify(this.selectedPackage));

    var originalPackage = this.originalCollection.filter(x => x.id == this.selectedPackage.id)[0];

    var globalStatusId = GlobalStatusEnum[this.selectedGlobalStatus];

    var digiposteStatusId = DigiposteStatusEnum[this.selectedDigiposteStatus];
    var mailevaStatusId = MailevaStatusEnum[this.selectedMailevaStatus];
    var okoroStatusId = OkoroStatusEnum[this.selectedOkoroStatus];

    // Mise à jour du statut global
    if (this.selectedGlobalStatus != originalPackage.statusGlobal) {
      
      packageToUpdate.statusGlobalId = globalStatusId;
      packageToUpdate.statusGlobal = this.selectedGlobalStatus;
      packageToUpdate.isStatusGlobalChanged = true;

      this.packageService.updatePackageStatus(packageToUpdate).subscribe(result => {
        this.loadingButton = false;

        if (result == true) {
          this.selectedPackage.statusGlobal = this.selectedGlobalStatus;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Statut mis à jour' });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur s'est produite" });
        }

        this.note = "";
        this.freezeButton = false;
        this.dialogDisplay = false;
        this.reinitializeCompteur();
      });
    }
    // Mise à jour du statut Digiposte
    if (this.selectedDigiposteStatus != originalPackage.statusDigiposte) {
      
      packageToUpdate.statusDigiposteId = digiposteStatusId;
      packageToUpdate.statusDigiposte = this.selectedDigiposteStatus;
      packageToUpdate.isStatusGlobalChanged = false;
      packageToUpdate.isStatusDigiposteChanged = true;

      this.packageService.updatePackageStatus(packageToUpdate).subscribe(result => {
        if (result == true) {
          this.selectedPackage.statusDigiposte = this.selectedDigiposteStatus;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Statut mis à jour' });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur s'est produite" });
        }

        this.note = "";
        this.freezeButton = false;
        this.dialogDisplay = false;
        this.reinitializeCompteur();
      });
    } //Mise à jour du statut Maileva
    if (this.selectedMailevaStatus != originalPackage.statusMaileva) {
      // call api
      packageToUpdate.statusMailevaId = mailevaStatusId;
      packageToUpdate.statusMaileva = this.selectedMailevaStatus;
      packageToUpdate.isStatusGlobalChanged = false;
      packageToUpdate.isStatusDigiposteChanged = false;
      packageToUpdate.isStatusMailevaChanged = true;

      this.packageService.updatePackageStatus(packageToUpdate).subscribe(result => {
        if (result == true) {
          this.selectedPackage.statusMaileva = this.selectedMailevaStatus;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Statut mis à jour' });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur s'est produite" });
        }

        this.note = "";
        this.freezeButton = false;
        this.dialogDisplay = false;
        this.reinitializeCompteur();
      });
    } // Mise à jour du statut Okoro
    if (this.selectedOkoroStatus != originalPackage.statusOkoro) {
      // call api
      packageToUpdate.statusOkoroId = okoroStatusId;
      packageToUpdate.statusOkoro = this.selectedOkoroStatus;
      packageToUpdate.isStatusGlobalChanged = false;
      packageToUpdate.isStatusDigiposteChanged = false;
      packageToUpdate.isStatusMailevaChanged = false;
      packageToUpdate.isStatusOkoroChanged = true;

      this.packageService.updatePackageStatus(packageToUpdate).subscribe(result => {
        if (result == true) {
          this.selectedPackage.statusOkoro = this.selectedOkoroStatus;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Statut mis à jour' });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur s'est produite" });
        }

        this.note = "";
        this.freezeButton = false;
        this.dialogDisplay = false;
        this.reinitializeCompteur();
      });
    }
  }

  onRowEditCancel(selectedPackage: PackageModel, index: number) {
    this.reinitializeCompteur();

  }

  reinitializeCompteur() {
    clearInterval(this.intervalId);
    this.compteur = this.refreshSecond;
    this.intervalId = setInterval(() => {
      if (this.compteur > 0) {
        this.compteur = this.compteur - 1;
      }
      else {
        this.hideCompteur = true;
      }
    }, 1000);

    this.subscribeRefresh();
    this.hideCompteur = false;
  }
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndDownloadBlobFile(body, filename, extension) {
  const blob = new Blob([body]);
  const fileName = `${filename}${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
