import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from "primeng/api";
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from "@angular/forms";
import { UserService } from "../../Services/user.service";
import { RoleEnum } from "../../models/roleEnum";
import { UserToAdd } from "../../models/UserToAdd";
import { UserModel } from "../../models/UserModel";
import { ActifUser } from "../../models/ActifUser";
import { WsRoleEnum } from "../../models/WsRoleEnum";

@Component({
  templateUrl: './editUsers.component.html',
  styleUrls: ['./editUsers.component.css'],
  providers: [MessageService]
})
export class EditUsers {

  userForm: FormGroup;
  roles: any[];
  originalPartners: any[];
  originalSenders: any[];
  originalProdAutoPartners: any[];
  originalProdAutoSenders: any[];
  partners: any[];
  senders: any[];
  disablePartnerDropdown: boolean;
  disableSenderDropdown: boolean;
  submitted = false;
  userToEdit: UserModel;
  loading: boolean = false;
  isOpenAccordion: boolean = false;
  isWsUser: boolean = false;
  public currentUser: ActifUser;

  constructor(public activeModal: DynamicDialogRef, public config: DynamicDialogConfig,
    private userFormBuilder: FormBuilder, private userService: UserService, private messageService: MessageService) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  ngOnInit() {

    this.userToEdit = this.config.data.user;
    this.isWsUser = this.config.data.isWsUser;

    this.userForm = this.userFormBuilder.group({
      firstname: new FormControl(this.userToEdit.firstname, { validators: Validators.required }),
      lastname: new FormControl(this.userToEdit.lastname, { validators: Validators.required }),
      username: new FormControl(this.userToEdit.username, { validators: Validators.required }),
      roleId: new FormControl(this.userToEdit.roleId, [Validators.required, Validators.min(1)]),
      partnerId: new FormControl(this.userToEdit.partnerId, [Validators.required, Validators.min(1)]),
      clientId: new FormControl(this.userToEdit.clientId, [Validators.required, Validators.min(1)]),
      password: new FormControl(""),
      confirmPassword: new FormControl(""),
      email: new FormControl(this.userToEdit.emailAddress, [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,4}$")]),
      active: new FormControl(this.userToEdit.active, { validators: Validators.required })
    }, {
        validator: this.MustMatch('password', 'confirmPassword', true)
    });

    this.roles = this.config.data.roles.map(x => (
      {
        value: x.id,
        label: x.label
      }));

    this.originalPartners = this.config.data.partners.map(x => ({
      value: x.id,
      label: x.label
    }));

    this.originalSenders = this.config.data.senders;

    if (!this.isWsUser) {
      this.originalProdAutoPartners = this.config.data.prodAutoPartners.map(x => ({
        value: x.id,
        label: x.label
      }));

      this.originalProdAutoSenders = this.config.data.prodAutoSenders.map(x => ({
        value: x.id,
        label: x.label
      }));
    }

    this.f.partnerId.disable();
    this.f.clientId.disable();

    if (this.isWsUser) {
      if (this.userToEdit.roleId == WsRoleEnum.PART) {
        this.partners = this.originalPartners;
        this.f.partnerId.enable();
      }
      else if (this.f.roleId.value == WsRoleEnum.SENDER) {
        this.senders = this.originalSenders.filter(sender => sender.partnerId == this.f.partnerId.value);
        this.senders = this.senders.map(x => ({
          value: x.id,
          label: x.pid
        }));

        console.log(this.originalSenders);
        console.log(this.f.partnerId.value);
        console.log(this.senders);
        this.partners = this.originalPartners;

        this.f.partnerId.enable();
        this.f.clientId.enable();
      }
    }
    else {
      if (this.userToEdit.roleId == RoleEnum.PART) {
        this.partners = this.originalPartners;
        this.f.partnerId.enable();
      }
      else if (this.userToEdit.roleId == RoleEnum.PRODAUTO_PART) {
        this.partners = this.originalProdAutoPartners;
        this.f.partnerId.enable();
      }
      else if (this.f.roleId.value == RoleEnum.SENDER) {
        this.senders = this.originalSenders.filter(sender => sender.partnerId == this.f.partnerId.value);
        this.senders = this.senders.map(x => ({
          value: x.id,
          label: x.label
        }));

        this.partners = this.originalPartners;

        this.f.partnerId.enable();
        this.f.clientId.enable();
      }
      else if (this.f.roleId.value == RoleEnum.PRODAUTO_SENDER) {
        this.senders = this.config.data.prodAutoSenders.filter(sender => sender.partnerId == this.f.partnerId.value);
        this.senders = this.senders.map(x => ({
          value: x.id,
          label: x.label
        }));

        this.partners = this.originalProdAutoPartners;
        this.f.partnerId.enable();
        this.f.clientId.enable();
      }
    }    

    if (this.userToEdit.username == this.currentUser.username) {
      this.f.username.disable();
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.userForm.controls; }

  MustMatch(controlName: string, matchingControlName: string, isActive: boolean) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  onRoleChanged(e) {
    this.f.partnerId.setValue("");
    this.f.clientId.setValue("");

    let roleCode = this.config.data.roles.find(x => x.id == this.f.roleId.value).code;

    let roleId;

    if (this.isWsUser) {
      roleId = WsRoleEnum[roleCode];

      if ((roleId == WsRoleEnum.PART.toString())
        || (roleId == WsRoleEnum.SENDER.toString())) {
        // enable partner dropdown && disable sender dropdown

        this.partners = this.originalPartners;

        this.f.partnerId.enable();
        this.f.clientId.disable();
      }
      else {
        this.f.partnerId.disable();
        this.f.clientId.disable();
      }
    }
    else {
      roleId = RoleEnum[roleCode];

      if ((roleId == RoleEnum.PART.toString())
        || (roleId == RoleEnum.SENDER.toString())) {
        // enable partner dropdown && disable sender dropdown

        this.partners = this.originalPartners;

        this.f.partnerId.enable();
        this.f.clientId.disable();
      }
      else if ((roleId == RoleEnum.PRODAUTO_PART.toString())
        || (roleId == RoleEnum.PRODAUTO_SENDER.toString())) {
        this.partners = this.originalProdAutoPartners;

        this.f.partnerId.enable();
        this.f.clientId.disable();
      }
      else {
        this.f.partnerId.disable();
        this.f.clientId.disable();
      }
    }

  }

  onPartnerChanged(e) {

    if (this.isWsUser) {
      if (this.f.roleId.value == WsRoleEnum.SENDER) {
        this.senders = this.config.data.senders.filter(sender => sender.partnerId == this.f.partnerId.value);
        this.senders = this.senders.map(x => ({
          value: x.id,
          label: x.pid
        }));

        console.log(this.config.data.senders);
        console.log(this.f.partnerId.value);
        console.log(this.senders);

        this.f.clientId.enable();

        if (this.f.partnerId.value == "") {
          this.f.clientId.disable();
        }
      }
      else {
        this.f.clientId.setValue(null);
        this.f.clientId.disable();
      }
    }
    else {
      if (this.f.roleId.value == RoleEnum.SENDER) {
        this.senders = this.config.data.senders.filter(sender => sender.partnerId == this.f.partnerId.value);
        this.senders = this.senders.map(x => ({
          value: x.id,
          label: x.label
        }));

        this.f.clientId.enable();

        if (this.f.partnerId.value == "") {
          this.f.clientId.disable();
        }
      }
      else if (this.f.roleId.value == RoleEnum.PRODAUTO_SENDER.toString()) {
        this.senders = this.config.data.prodAutoSenders.filter(sender => sender.partnerId == this.f.partnerId.value);
        this.senders = this.senders.map(x => ({
          value: x.id,
          label: x.label
        }));
        this.f.clientId.enable();
      }
      else {
        this.f.clientId.setValue(null);
        this.f.clientId.disable();
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }
    this.loading = true;

    let roleId = this.f.roleId.value;

    let userToBeUpdated: UserToAdd = new UserToAdd();

    userToBeUpdated.id = this.userToEdit.id;
    userToBeUpdated.firstname = this.userForm.value.firstname;
    userToBeUpdated.lastname = this.userForm.value.lastname;
    userToBeUpdated.username = this.userForm.value.username;
    userToBeUpdated.emailAddress = this.userForm.value.email;

    if (this.isWsUser) {
      userToBeUpdated.role = WsRoleEnum[roleId];
    } else {
      userToBeUpdated.role = RoleEnum[roleId];
    }
    
    userToBeUpdated.password = this.userForm.value.password;

    if (this.isWsUser) {
      userToBeUpdated.partnerOrClientId = roleId != null ? roleId == WsRoleEnum.PART.toString() ?
        this.userForm.value.partnerId :
        this.userForm.value.clientId :
        null;
    } else {
      userToBeUpdated.partnerOrClientId = roleId != null ? roleId == RoleEnum.PART.toString() || roleId == RoleEnum.PRODAUTO_PART.toString() ?
        this.userForm.value.partnerId :
        this.userForm.value.clientId :
        null;
    }
    
    userToBeUpdated.active = this.userForm.value.active;

    this.f.active.disable();
    this.f.firstname.disable();
    this.f.lastname.disable();
    this.f.username.disable();
    this.f.roleId.disable();
    this.f.partnerId.disable();
    this.f.clientId.disable();
    this.f.email.disable();

    // si c'est un utilisateur des webServices Concentrator
    if (this.isWsUser) {

      this.userService.updateWsUser(userToBeUpdated).subscribe(user => {
        this.loading = false;

        if (user != null) {
          this.activeModal.close(user);
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "le nom d'utilisateur existe déjà. Merci de choisir un autre nom d'utilisateur.", life: 4000 });
          this.f.active.enable();
          this.f.firstname.enable();
          this.f.lastname.enable();
          this.f.username.enable();
          this.f.roleId.enable();
          this.f.partnerId.enable();
          this.f.clientId.enable();
          this.f.email.enable();
        }
      })
    }
    else {

      this.userService.updateUser(userToBeUpdated).subscribe(user => {
        this.loading = false;

        if (user != null) {
          this.activeModal.close(user);
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "le nom d'utilisateur existe déjà. Merci de choisir un autre nom d'utilisateur.", life: 4000 });
          this.f.active.enable();
          this.f.firstname.enable();
          this.f.lastname.enable();
          this.f.username.enable();
          this.f.roleId.enable();
          this.f.partnerId.enable();
          this.f.clientId.enable();
          this.f.email.enable();
        }
      })
    }    
  }

  onReset() {
    this.submitted = false;
    this.activeModal.close();
  }

  onTabOpen() {
    // Add validators
    this.isOpenAccordion = true;
    this.userForm.setControl('password', new FormControl('', [Validators.required, Validators.minLength(6)]));
    this.userForm.setControl('confirmPassword', new FormControl('', { validators: Validators.required }));
  }

  onTabClose() {
    // Remove validators
    this.isOpenAccordion = false;
    this.userForm.setControl('password', new FormControl());
    this.userForm.setControl('confirmPassword', new FormControl());
  }

}
