import { Component } from '@angular/core';
import { DynamicDialogRef, MessageService, LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { PackageService } from '../Services/package.service';
import { DocumentModel } from '../models/DocumentModel';
import { DigiposteStatusEnum } from '../models/DigiposteStatusEnum';
import { MailevaStatusEnum } from '../models/MailevaStatusEnum';
import { ActifUser } from '../models/ActifUser';

@Component({
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentsData {

  documentsCollection: DocumentModel[];
  currentUser: ActifUser;
  globalDocumentStatus: any[];
  digiposteDocumentStatus: any[];
  mailevaDocumentStatus: any[];
  okoroDocumentStatus: any[];
  columns: any[];
  loadingDoc: boolean;
  selectedDigiposteStatus: string;
  selectedMailevaStatus: string;
  selectedOkoroStatus: string;
  totalRecords: number;
  packageId: string;
  hasMaileva: DocumentModel;
  event: LazyLoadEvent;

  constructor(private packageService: PackageService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private messageService: MessageService) {
    this.loadingDoc = true;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  ngOnInit() {

    this.columns = [
      { field: 'statusGlobal', header: 'Statut global' },
      { field: 'docId', header: 'Id Document' },
      { field: 'matricule', header: 'Matricule' },
      { field: 'trackId', header: 'Maileva Id track' },
      { field: 'clientName', header: 'Client' },
      { field: 'clientCode', header: 'Code client' },
      { field: 'statusDigiposte', header: 'Statut' },
      { field: 'statusMaileva', header: 'Statut' },
      { field: 'statusOkoro', header: 'Statut' }
    ];

    this.packageId = this.config.data.id;
    this.totalRecords = this.config.data.totalDocCount;
    this.globalDocumentStatus = this.config.data.globalStatus;
    this.digiposteDocumentStatus = this.config.data.digiposteStatus;
    this.mailevaDocumentStatus = this.config.data.mailevaStatus;
    this.okoroDocumentStatus = this.config.data.okoroStatus;
    this.loadingDoc = this.loadingDoc;

    
  }

  loadDocumentLazy(event: LazyLoadEvent) {
    this.loadingDoc = true;
    this.event = event;

    let filter = {
      packageId: this.packageId,
      first: event.first,
      rows: event.rows,
      statusGlobal: event.filters != null && event.filters.statusGlobal != null ? event.filters.statusGlobal.value : null,
      documentId: event.filters != null && event.filters.docId != null ? event.filters.docId.value : null,
      matricule: event.filters != null && event.filters.matricule != null ? event.filters.matricule.value : null,
      mailevaTrackId: event.filters != null && event.filters.trackId != null ? event.filters.trackId.value : null,
      clientName: event.filters != null && event.filters.clientName != null ? event.filters.clientName.value : null,
      clientCode: event.filters != null && event.filters.clientCode != null ? event.filters.clientCode.value : null,
      statusDigiposte: event.filters != null && event.filters.statusDigiposte != null ? event.filters.statusDigiposte.value : null,
      statusMaileva: event.filters != null && event.filters.statusMaileva != null ? event.filters.statusMaileva.value : null,
      statusOkoro: event.filters != null && event.filters.statusOkoro != null ? event.filters.statusOkoro.value : null,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };

    setTimeout(() => {
      this.packageService.getDocumentsByIdPackage(filter).subscribe(documents => {
        this.totalRecords = documents.count;
        this.documentsCollection = documents.documentList;

        if (this.documentsCollection != null) {
          this.hasMaileva = this.documentsCollection.find(x => x.statusMailevaId != '');
        }        

        this.loadingDoc = false;
      });
    }, 1000);
  }

  getZip(selectedDocument) {
    this.loadingDoc = true;
    let documentId = selectedDocument.id;

    this.packageService.getMailevaZip(documentId).subscribe((base64Pdf: string) => {
      this.loadingDoc = false;

      const arrayBuffer = base64ToArrayBuffer(base64Pdf);
      createAndDownloadBlobFile(arrayBuffer, selectedDocument.id, '.pjs');
    },
      error => {
        this.loadingDoc = false;
        this.messageService.add({ severity: 'warn', summary: 'Attention', detail: "Erreur lors du téléchargement de l'xml.", life: 4000 });
        console.error(error);
      }
    );
  }

  onRowEditInit(selectedDocument: DocumentModel) {
    this.selectedMailevaStatus = selectedDocument.statusMaileva;
  }

  onRowEditSave(selectedDocument: DocumentModel) {
    this.loadingDoc = true;
    var documentToUpdateMaileva = JSON.parse(JSON.stringify(selectedDocument));

    var originalDocument = this.documentsCollection.filter(x => x.id == selectedDocument.id)[0];

    var mailevaStatusId = MailevaStatusEnum[this.selectedMailevaStatus];

    if (this.selectedMailevaStatus != originalDocument.statusMaileva) {
      // call api
      documentToUpdateMaileva.statusMailevaId = mailevaStatusId;
      documentToUpdateMaileva.statusMaileva = this.selectedMailevaStatus;

      this.packageService.updateDocumentStatus(documentToUpdateMaileva).subscribe(result => {
        if (result == true) {
          selectedDocument.statusMaileva = this.selectedMailevaStatus;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Statut mis à jour' });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur s'est produite" });
        }

        this.loadingDoc = false;
      });
    }
  }

  onRowEditCancel(doc: DocumentModel, index: number) {

  }
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndDownloadBlobFile(body, filename, extension) {
  const blob = new Blob([body]);
  const fileName = `${filename}${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
