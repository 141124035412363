import { Component, ViewEncapsulation } from '@angular/core';
import { ReferentielService } from 'src/app/Services/referentiel.service';
import { ActifUser } from 'src/app/models/ActifUser';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DialogService, MessageService, LazyLoadEvent } from 'primeng/api';
import { RecipientData } from './recipient/recipientData.component';
import { UserService } from '../Services/user.service';
import { RoleEnum } from '../models/roleEnum';
import { RecipientModel } from '../models/RecipientModel';

@Component({
  templateUrl: './referentiel.component.html',
  styleUrls: ['./referentiel.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, MessageService]
})
export class Referentiel {

  loading: boolean;
  loadingButton: boolean;
  showTableCard: boolean;
  loadingRef: boolean;
  loadingDownloadButton: boolean;
  submitted = false;

  currentUser: ActifUser;
  referentielCollection: any[];
  columns: any[];
  partners: any[];
  senders: any[];
  originalSenders: any[];
  destinations: any[];
  recipientStatus: any[];
  selectedPartner: any;
  event: LazyLoadEvent;
  totalRecords: number;

  recipientForm: FormGroup;

  constructor(private referentielService: ReferentielService, private userService: UserService, private recipientFormBuilder: FormBuilder, public dialogService: DialogService, private messageService: MessageService) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.loading = true;
    this.showTableCard = false;
    this.loadingButton = false;
  }

  ngOnInit() {
    let requestId: string;

    if (this.currentUser.roleId == RoleEnum.PART) {
      requestId = this.currentUser.partnerId;
    }

    this.columns = [
      { field: 'clientId', header: 'Id client' },
      { field: 'pid', header: 'Client' },
      { field: 'firstName', header: 'Prénom' },
      { field: 'lastName', header: 'Nom' },
      { field: 'statusLabel', header: 'Statut' },
      { field: 'destination', header: 'Destination' },
      { field: 'routeCode', header: 'Code routage' }      
    ];

    this.userService.getAllPartners().subscribe(partners => {
      this.partners = partners;
      this.selectedPartner = this.partners.find(x => x.id == requestId);
    });

    this.referentielService.getRecipientStatus().subscribe(status => {
      this.recipientStatus = status;

      this.recipientStatus = this.recipientStatus.map(x => (
        {
          value: x.code,
          label: x.label
        }));
      this.recipientStatus.unshift({
        value: null,
        label: null
      });
    });

    this.referentielService.getSenders(requestId).subscribe(senders => {
      this.originalSenders = senders

      if (this.currentUser.roleId == RoleEnum.ADMIN || this.currentUser.roleId == RoleEnum.EXPLOIT) {
        this.recipientForm.setControl('partner', new FormControl("", { validators: Validators.required }));
        this.recipientControl.sender.disable();
      } else if (this.currentUser.roleId == RoleEnum.SENDER) {
        this.recipientControl.sender.disable();

        this.senders = this.originalSenders.map(x => (
          {
            value: x.pid,
            label: x.label + ' (' + x.pid + ')'
          }));

        this.getRecipients();
      } else {
        this.senders = this.originalSenders.map(x => (
          {
            value: x.pid,
            label: x.label + ' (' + x.pid + ')'
          }));
      }
      this.loading = false;

    });

    this.destinations = [
      {
        value: null,
        label: null
      },
      {
        value: "00000010",
        label: "DIGIPOSTE"
      },
      {
        value: "00000100",
        label: "MAILEVA"
      },
      {
        value: "00001000",
        label: "SENDER"
      },
    ];

    this.recipientForm = this.recipientFormBuilder.group({
      sender: new FormControl("", { validators: Validators.required })
    });

  }

  // convenience getter for easy access to form fields
  get recipientControl() { return this.recipientForm.controls; }

  onPartnerChanged(e) {
    this.recipientControl.sender.setValue("");

    this.selectedPartner = e;

    if (this.selectedPartner != undefined) {
      if (this.selectedPartner.id == 0) {
        this.recipientControl.sender.setValue("");
        this.recipientControl.sender.disable();
      }
      else {
        this.senders = this.originalSenders.filter(sender => sender.partnerId == this.selectedPartner.id);
        this.senders = this.senders.map(x => (
          {
            value: x.pid,
            label: x.label + ' (' + x.pid + ')'
          }));
        this.recipientControl.sender.enable();
      }
    }
  }

  getRecipients() {
    this.submitted = true;
    this.showTableCard = false;
    this.loadingButton = true;
    this.loadingRef = true;

    if (this.currentUser.roleId == RoleEnum.SENDER) {
      this.recipientForm.value.sender = this.senders.find(x => x.value == this.currentUser.clientLabel);      
    }
    else {
      // stop here if form is invalid
      if (this.recipientForm.invalid) {
        this.loadingButton = false;
        return;
      }
    }    

    let filter = {
      pid: this.recipientForm.value.sender.value,
      first: 0,
      rows: 10
    };

    setTimeout(() => {
      this.referentielService.getAllReferentiel(filter).subscribe(users => {
        this.totalRecords = users.count;
        this.referentielCollection = users.recipientList;
        this.showTableCard = true;
        this.submitted = false;
        this.loadingButton = false;
      });
    }, 1000);

  }

  getRecipientCsv() {
    console.log("TEST");
    this.submitted = true;
    this.loadingDownloadButton = true;
    this.loadingRef = true;

    let pid = this.recipientForm.value.sender.value;

    this.referentielService.getReferentielCsv(pid).subscribe((base64Pdf: string) => {
      this.loadingDownloadButton = false;
      this.submitted = false;
      this.loadingRef = false;

      const arrayBuffer = base64ToArrayBuffer(base64Pdf);
      createAndDownloadBlobFile(arrayBuffer, 'Référentiel_' + pid);
    },
      error => {
        this.loadingDownloadButton = false;
        this.submitted = false;
        this.loadingRef = false;
        this.messageService.add({ severity: 'error', summary: 'Attention', detail: "Une erreur inattendue s'est produite", life: 4000 });
      }
    );      
  }

  loadRefLazy(event: LazyLoadEvent) {

    this.loadingRef = true;
    this.event = event;

    let filter = {
      pid: this.recipientForm.value.sender.value,
      first: this.event.first,
      rows: this.event.rows,
      matricule: this.event.filters != null && this.event.filters.clientId != null ? this.event.filters.clientId.value : null,
      clientName: this.event.filters != null && this.event.filters.pid != null ? this.event.filters.pid.value : null,
      lastName: this.event.filters != null && this.event.filters.lastName != null ? this.event.filters.lastName.value : null,
      firstName: this.event.filters != null && this.event.filters.firstName != null ? this.event.filters.firstName.value : null,
      status: this.event.filters != null && this.event.filters.statusLabel != null ? this.event.filters.statusLabel.value : null,
      destination: this.event.filters != null && this.event.filters.destination != null ? this.event.filters.destination.value : null,
      routeCode: this.event.filters != null && this.event.filters.routeCode != null ? this.event.filters.routeCode.value : null,
      sortField: this.event.sortField,
      sortOrder: this.event.sortOrder
    };

    setTimeout(() => {
      this.referentielService.getAllReferentiel(filter).subscribe(users => {
        this.totalRecords = users.count;
        this.referentielCollection = users.recipientList;
        this.showTableCard = true;
        this.submitted = false;
        this.loadingButton = false;
        this.loadingRef = false;
      });
    }, 1000);
    
  }

  showInformation(selectedRecipient: RecipientModel) {
    let selectedClientId = selectedRecipient.clientId;
    let pid = selectedRecipient.pid;

    const ref = this.dialogService.open(RecipientData, {
      data: {
        // Passing data to documentData component
        id: selectedClientId,
        pid: pid
      },
      header: 'Id client : ' + selectedClientId,
      width: '90%',
      contentStyle: { "max-height": "600px", "overflow": "auto" }
    });
  }
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndDownloadBlobFile(body, filename, extension = 'csv') {
  const blob = new Blob([body]);
  const fileName = `${filename}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
