import { Component } from '@angular/core';
import { DynamicDialogRef, MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { ActifUser } from 'src/app/models/ActifUser';
import { ReferentielService } from 'src/app/Services/referentiel.service';
import { RecipientDataModel } from 'src/app/models/RecipientDataModel';

@Component({
  templateUrl: './recipientData.component.html',
  styleUrls: ['./recipientData.component.css']
})
export class RecipientData {

  recipient: RecipientDataModel;
  currentUser: ActifUser;
  columns: any[];
  loadingRec: boolean;
  totalRecords: number;
  yearRange: string;
  fr: any;

  constructor(private referentielService: ReferentielService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private messageService: MessageService) {
    this.loadingRec = true;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  ngOnInit() {

    var date = new Date();
    date.setFullYear(new Date().getFullYear());

    this.yearRange = (new Date().getFullYear() - 10) + ":" + date.getFullYear();

    this.fr = {
      firstDayOfWeek: 1,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec"],
      today: "Aujourd'hui",
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };

    this.columns = [
      { field: 'id', header: 'Id Document' },
      { field: 'filename', header: 'Nom du pdf' },
      { field: 'type', header: 'Type' },
      { field: 'title', header: 'Titre' },
      { field: 'creationDate', header: 'Date de création' },
      { field: 'destination', header: 'Destination' }
    ];

    let clientId = this.config.data.id;
    let pid = this.config.data.pid;

    this.referentielService.getRecipientByClientId(clientId, pid).subscribe(result => {
      this.recipient = result;
      
      this.totalRecords = this.recipient.documentList.length;
      this.loadingRec = false;
    });
  }

  
}
